body {
  font-family: sans-serif;
  padding: 1em;
}

.ais-ClearRefinements {
  margin: 1em 0;
}

.ais-SearchBox {
  margin: 1em 0;
}

.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  float: left;
  width: 200px;
}

.right-panel {
  margin-left: 210px;
}

.ais-InstantSearch {
  max-width: 1200px;
  overflow: hidden;
  margin: 0 auto;
}

.ais-InfiniteHits-item {
  margin-bottom: 1em;
  display: block; /* Or use flex with flex-direction: column; */
  width: calc(100% - 1rem);
}

.ais-InfiniteHits-item img {
  margin-right: 1em;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5em;
}

.hit-name {
  margin-bottom: 0.5em;
}

.hit-description {
  font-size: 150%;
  margin-bottom: 0.5em;
  color: grey;
}

.start_time_slider {
  font-size: 100%;
}

.hit-info {
  font-size: 75%;
}

.hit-audio {
  font-size: 90%;
  align-items: center; 
  display:inline-block;
}

.ais-Hits-list {
  display: block; /* Or use flex with flex-direction: column; */
}

